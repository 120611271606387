import React from 'react'
import logo from '../imagenes/LOGO_STAIRUS.webp';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import '../i18n.jsx';
import { useTranslation} from 'react-i18next';
import GITHUB from '../imagenes/github-dark.svg';
import INSTAGRAM from '../imagenes/instagram_dark.svg';

const Footer = () => {
  const {t, i18n} = useTranslation("global");

  return (
    <footer className='footer'>
        <img src={logo} alt="" />
        <div className='mitad__footer'>
          <h3 className='footer__name'>STAIRUS</h3>
          <div className='contacto__redes-footer'>
              <a className='red__footer' href='https://www.instagram.com/stairusdev/' target='blank'>
              <img src={INSTAGRAM} alt="" />
              </a>
              <a className='red__footer' href='https://github.com/agusmcinnes' target='blank'>
              <img src={GITHUB} alt=""/>
              </a>
          </div>
        </div>
        <Link to='/contact' className='footer__link'>{t('header.contact')}</Link>
    </footer>
  )
}

export default Footer;