import React, {useEffect, useRef} from 'react'
import mcinnes from '.././imagenes/mcinnes.webp'
import cLogo from '.././imagenes/c.svg'
import HTML5 from '.././imagenes/html5.svg'
import CSS3 from '.././imagenes/css.svg'
import JAVASCRIPT from '.././imagenes/javascript.svg'
import REACT from '.././imagenes/react.svg'
import GIT from '.././imagenes/git.svg'
import GITHUB from '.././imagenes/github-dark.svg'
import FIGMA from '.././imagenes/figma.svg'
import VS from '.././imagenes/vscode.svg'
import PHOTOSHOP from '.././imagenes/photoshop.svg'
import GIMP from '.././imagenes/gimp.svg'
import portal from '.././imagenes/portal-rick-and-morty.gif'
import { useTranslation } from 'react-i18next';


const AboutMe = () => {
    const {t, i18n} = useTranslation("global");
    useEffect(() => {
        const skills = document.querySelectorAll('.aparecer__animacion');
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animacion__skills');
                }
            });
        });
    
        // Aplica el observer a cada skill individualmente
        skills.forEach(skill => observer.observe(skill));
    }, []);
    
    const skillRefs = useRef([]);

    useEffect(() => {
      const handleMouseOver = (e) => {
        e.target.style.backgroundColor = '#FFF';
      };
  
      skillRefs.current.forEach((skillRef) => {
        skillRef.addEventListener('mouseover', handleMouseOver);
      });
  
      // Clean up the event listeners when the component unmounts
      return () => {
        skillRefs.current.forEach((skillRef) => {
          skillRef.removeEventListener('mouseover', handleMouseOver);
        });
      };
    }, []);

    return (
    <section className='aboutme'>
    <div className='aboutme__container'>
        <h2 className='aboutme__title'>{t('aboutMe.title')}</h2>
        <div className='mcinnes__card aparecer__animacion'>
            <h2 className='mcinnes__card-name text' >AGUSTIN <br /> MC INNES ALEMANY</h2>
            <h3 className='mcinnes__card-developer text'>Fronted Developer</h3>
            <p className='mcinnes__card-text text'>{t('aboutMe.text-1')}</p>
            <p className='mcinnes__card-text text'>{t('aboutMe.text-2')}</p>
            <img src={mcinnes} alt="" />
        </div>
        <div className='aboutme__skills'>
            <h2 className='skills__title'>Skills</h2>
        <div className='skills__img-container'>

            <div className='skills__wrapper'>
                    <span className='skill__type'>Develop skills</span>
                    <div className='skills__container'>
                        <div className='skill aparecer__animacion'> 
                            <img src={cLogo} alt=""  className='skill__img'/>
                            <span className='skill__name'>C</span>
                        </div>

                        <div className='skill aparecer__animacion'> 
                            <img src={HTML5} alt="" className='skill__img'/>
                            <span className='skill__name'>HTML</span>
                        </div>

                        <div className='skill aparecer__animacion'> 
                            <img src={CSS3} alt="" className='skill__img'/>
                            <span className='skill__name'>CSS</span>
                        </div>

                        <div className='skill aparecer__animacion'> 
                            <img src={JAVASCRIPT} alt="" className='skill__img'/>
                            <span className='skill__name'>JAVASCRIPT</span>
                        </div>

                        <div className='skill aparecer__animacion'> 
                            <img src={REACT} alt="" className='skill__img'/>
                            <span className='skill__name'>REACT</span>
                        </div>
                    </div>

            </div>

            <div className="skills__wrapper">
                <span className='skill__type'>Software skills</span>
                <div className="skills__container">
                    <div className='skill aparecer__animacion'> 
                            <img src={GIT} alt="" className='skill__img'/>
                            <span className='skill__name'>GIT</span>
                        </div>
                        <div className='skill aparecer__animacion'> 
                            <img src={GITHUB} alt="" className='skill__img'/>
                            <span className='skill__name'>GITHUB</span>
                        </div>
                        <div className='skill aparecer__animacion'> 
                            <img src={VS} alt="" className='skill__img'/>
                            <span className='skill__name'>VSC</span>
                        </div>
                </div>

            </div>

            <div className="skills__wrapper">
                <span className='skill__type'>Desing skills</span>
                <div className="skills__container">
                    <div className='skill aparecer__animacion'> 
                            <img src={FIGMA} alt="" className='figma skill__img'/>
                            <span className='skill__name'>FIGMA</span>
                        </div>
                        <div className='skill aparecer__animacion'> 
                            <img src={PHOTOSHOP} alt="" className='skill__img'/>
                            <span className='skill__name'>PHOTOSHOP</span>
                        </div>
                        <div className='skill aparecer__animacion'> 
                            <img src={GIMP} alt="" className='skill__img'/>
                            <span className='skill__name'>GIMP</span>
                        </div>
                </div>

            </div>
        </div>
        </div>
    </div>
    <img src={portal} className='portal__img' alt='portal rick y morty'></img>
</section>
)
}

export default AboutMe